.connection-line {
	height: 100px;
	width: 1px;
	background-color: $main_accent;

	margin-top: -50px;
	margin-bottom: -50px;
	margin-left: 50%;
}

.block-holder__bg {
	position: absolute;

	background-color: $gray;
  top: 0;
  height: 100%;
	width: 60%;
	z-index: -1;
}

.block-holder__bg--left {
  left: 0;
}

.block-holder__bg--right {
  right: 0;
}

.block-holder__bg--full {
  width: 100%;
}

@media (max-width: $screen_zero) {
  .block-holder__bg {
    display: none;
  }

  .block-holder__bg--full {
    display: block;
  }
}
