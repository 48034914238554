.video section {
	grid-template-columns: 275px 1fr;
	grid-template-rows: auto 1fr;
}

.video .container {
	align-items: start;
}

.video .container__title {
	grid-column: 1;
	grid-row: 1;
	justify-self: start;

	margin-left: 60px;
	margin-bottom: 16px;
}
.video__list--wrapper {
	grid-column: 1;
	grid-row: 2 / 3;

	position: relative;
	height: 100%;
}

.video__list {
	display: flex;
	flex-direction: column;

	position: absolute;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	padding-right: 10px;
}

.video__list__item {
	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;
	color: $main_accent;
	box-shadow: 0 0 10px black;
	margin: 10px;
	margin-bottom: 14px;
  transition: all .2s ease-in-out;
}

.video__list__item:hover,
.video__list__item:active {
	color: $bright_gray;
	box-shadow: none;
	transform: scale(1.05);

	.video__list__item__img {
		filter: blur(1.5px);
		opacity: 0.5;
	}
}

.video__list__item__img {
	width: 245px;
	height: calc(245px * 0.6);
	object-fit: cover;
}

.video__list__item__icon {
	display: flex;
  align-items: center;
	justify-content: center;
	
  position: absolute;
  font-size: 800%;
}

.video .container__content {
	grid-column: 2;
	grid-row: 1 / 3;

	margin: 0;
}

@media (max-width: $screen_one) {
	.video section {
		grid-template-columns: 1fr;
		grid-template-rows: auto;
	}

	.video .container__title {
		grid-column: 1;
		grid-row: 1;
	}

	.video .container__content {
		grid-column: 1;
		grid-row: 2;
	}

	.video__list--wrapper {
		grid-column: 1;
		grid-row: 3;

		height: 174px;
	}

	.video__list {
		flex-direction: row;

		overflow-y: hidden;
		overflow-x: scroll;
	}
}

@media (max-width: $screen_two) {
	.video section {
    margin: 0;
	}
	
	.video .container__title {
		margin-left: 60px;
		margin-bottom: 30px;
	}

	.video .video__list--wrapper {
		margin: 0 20px;
	}
}
