.hero {
	align-items: center;

	height: 100vh;
}

.hero section {
	grid-template-columns: 1fr 1fr;

	margin: 0 80px;
}

.hero__bg {
	position: absolute;

	background: url(/img/bg-hero.png) no-repeat 40% center;
	background-size: cover;
	opacity: 0.9;
	height: 100%;
	width: 50%;
	right: 0;
	z-index: -20;
}

.hero__email {
	position: absolute;
	display: flex;
	justify-content: center;

	height: 100%;
	left: 0;
	margin-left: 3%;
	writing-mode: vertical-rl;
	transform: rotate(-180deg);
}

.hero__text-block {
	grid-column: 1;

	border-bottom: 1px solid $main_accent;
	padding-bottom: 20px;
}

.hero__text-block__name {
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 900;
	letter-spacing: 4px;
}

.hero__text-block__description {
	text-align: justify;

	margin: 0;
}

.hero__icons {
	position: absolute;
	display: flex;
	align-items: center;

	height: 100%;
	right: 0;
	text-align: center;
	font-size: 120%;
	line-height: 40px;
	margin-right: 3%;
}

.hero__icons > ul {
	list-style-type: none;
}

@media (max-width: $screen_one) {
	.hero__text-block {
		grid-column: 1 / 3;
	}
}

@media (max-width: $screen_two) {
	.hero section {
		margin: 0 40px;
	}
}
