.lines__line {
	position: fixed;
	width: 1px;
	height: 100%;
	background-color: hsla(0, 0%, 92%, 0.08);
	z-index: -10;
}

.lines__line:nth-child(1) {
	margin-left: 10%;
}

.lines__line:nth-child(2) {
	margin-left: 30%;
}

.lines__line:nth-child(3) {
	margin-left: 50%;
}

.lines__line:nth-child(4) {
	margin-left: 70%;
}

.lines__line:nth-child(5) {
	margin-left: 90%;
}
