.music section {
	grid-template-columns: 1fr auto;
	grid-template-rows: auto 1fr;
}

.music .container {
	align-items: start;
	
	margin-top: 40px;
}

.music .container__title {
	grid-column: 2;
	grid-row: 1;
	justify-self: end;

	margin-left: 40px;
	margin-right: 60px;
}

.music .container__content {
	grid-column: 1;
	grid-row: 1;

	margin: 0;
}

.music__player {
	margin-bottom: 40px;
}

.music__player:last-child {
	margin-bottom: 30px;
}

@media (max-width: $screen_one) {
	.music section {
		grid-template-columns: 1fr;
	}

	.music .container {
		justify-items: center;

		width: 100%;
		margin-top: 0;
	}

	.music .container__title {
		grid-column: 1;
		grid-row: 1;
	}

	.music .container__content {
		grid-column: 1;
		grid-row: 2;

		max-width: 700px;
	}
}
