.album section {
	grid-template-columns: 1fr;

	padding-top: 120px;
}

.album .container {
	align-items: start;
}

.album .container__title {
	grid-column: 1;
	grid-row: 1;
	justify-self: start;

	margin-left: 60px;
}

.album .container__content {
	grid-column: 1;
	grid-row: 2;
	justify-self: start;
}

.album .album-preview {
	display: flex;

	background-color: rgba($color: white, $alpha: 0.06);
	margin-bottom: 10px;

	transition: all 0.3s ease;
}

.album .album-preview__cover-stack {
	display: flex;
	flex-direction: column;
}

.album .album-preview__img {
	overflow: hidden;
	width: 340px;
	min-width: 340px;
	height: 340px;
	border-right: 6px solid $main_accent;
	margin-bottom: 15px;
}

.album .album-preview__img img {
	object-fit: cover;
	width: 100%;
	height: 100%;
	transition: all 0.3s ease;
}

.album .album-preview__paypal {
	background-color: white;
	border-radius: 3px;
	padding: 10px;
	z-index: 0;
}

.album .album-preview__paypal__price {
	color: $gray;
	text-align: center;
	margin-bottom: 10px;
}

.album .album-preview__body {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	
	width: 100%;
	margin: 2% 6%;
}

.album .album-preview__date {
	align-self: flex-end;

	color: $main_accent;
}

.album .album-preview__content {
	margin: 30px 0;
}

.album .album-preview__button {
	margin-top: auto;
}

.album .track-preview__header {
	display: flex;
	align-items: center;

	position: relative;
	cursor: pointer;
	width: 100%;
	height: 60px;
	background-color: rgba($color: white, $alpha: 0.06);
	margin-top: 10px;
}

.album .track-preview__index {
	display: flex;
	align-items: center;
	justify-content: center;

	height: 100%;
	width: 60px;
	border-right: 6px solid $main_accent;
	z-index: 1;
}

.album .track-preview__index .player-icon {
	display: none;
	font-size: 200%;
}

.album .track-preview__header:hover {
	background-color: rgba($color: white, $alpha: 0.1);

	.track-preview__index .index {
		display: none;
	}

	.track-preview__index .player-icon--play {
		display: block;
	}
}

.album .track-preview.playing {
	.track-preview__index .index {
		display: none;
	}

	.track-preview__index .player-icon--pause {
		display: block;
	}
}

.album .track-preview.playing:hover {
	.track-preview__index .index {
		display: none;
	}

	.track-preview__index .player-icon--play {
		display: none;
	}

	.track-preview__index .player-icon--pause {
		color: $main_accent;
	}
}

.album .track-preview__name {
	margin-left: 20px;
	z-index: 1;
}

.album .track-preview__lyrics-link {
	margin-left: auto;
	margin-right: 20px;
	z-index: 1;
}

.track-preview__progress-bar--wrapper {
	position: absolute;
	height: 100%;
	width: 100%;
	padding-left: 60px;
}

.track-preview__progress-bar {
	position: relative;
	background-color: $main_accent;
	opacity: 0.6;
	height: inherit;
	width: 0;
}

.album .track-preview__lyrics-text {
	columns: 2;
	background-color: rgba($color: white, $alpha: 0.06);
	border-top: 1px solid $main_accent;
	padding: 20px;
}

@media (max-width: 900px) {
	.album .album-preview__img {
		width: 240px;
		min-width: 240px;
		height: 240px;
	}
}

@media (max-width: 750px) {
	.album .album-preview {
		flex-direction: column;
	}

	.album .album-preview__img {
		width: 100%;
		height: 340px;
		border-right: none;
		border-bottom: 6px solid $main_accent;
	}

	.album .album-preview__body {
		align-items: center;
		
		margin: 3% 4%;
	}
}

@media (max-width: $screen_two) {
	.album .container {
		padding-bottom: 20px;
	}

	.album .container__title {
    margin-left: 0.8rem;
	}

	.album .album-preview__body {
		width: auto;
	}

	.album .track-preview__lyrics-text {
		columns: 1;
	}
}
