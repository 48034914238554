.all-news-header {
	align-items: center;
	height: 65vh;
	width: 100%;
}

.all-news-header section {
	grid-template-columns: 1fr 1fr;
	margin: 0 80px;
}

.all-news-header__bg {
	position: absolute;
	overflow: hidden;
	opacity: 0.9;
	height: 100%;
	width: auto;
	right: 0;
	z-index: -20;
}

.all-news-header__bg img {
	object-fit: cover;

	margin-top: -32%;
	margin-left: 35%;
	height: 220%;
}

@media (max-width: $screen_two) {
	.all-news-header section {
		margin: 0 20px;
	}

	.all-news-header__bg {
		width: 50%;
	}

	.all-news-header__bg img {
		margin-left: -65%;
    height: 190%;
    margin-top: -100%;
	}
}
