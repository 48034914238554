.gallery .react-photo-gallery--gallery img {
	transition: all 0.3s;
}

.gallery .react-photo-gallery--gallery img:hover {
	opacity: 0.7;
	transform: scale(1.02);

	filter: blur(1.5px);
}
