.about section {
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto 1fr;
}

.about .container {
	align-items: start;

	text-align: justify;
}

.about .expander {
	border-style: solid;
	border-color: $main_accent;
	border-width: 1px 0 1px 0;
	padding-bottom: 20px;
}

.about .container__title {
	grid-column: 1;
	grid-row: 1;
	justify-self: start;

	margin-left: 60px;
}

.about__picture {
	grid-column: 1;
	grid-row: 2 / 3;
	display: flex;

	position: relative;
	width: 100%;
	max-width: 600px;
	padding-right: 15px;
}

.about__picture__overlay {
	background-image: -moz-linear-gradient( 90deg, white 0%, $main_accent 100% );
	background-image: -webkit-linear-gradient( 90deg, white 0%, $main_accent 100% );
	background-image: -ms-linear-gradient( 90deg, white 0%, $main_accent 100% );
	opacity: 0.2;
	height: 100%;
	width: calc(100% - 15px);
	position: absolute;
	bottom: 0;
	transition: all 1s ease;
}

.about__picture__overlay:hover,
.about__picture__overlay:active {
	opacity: 0;
}

.about__picture img {
	width: 100%;
	height: 100%;
}

.about .container__content {
	grid-column: 2;
	grid-row: 1 / 3;
	display: flex;

	margin: 0;
	padding-left: 15px;
}

.about .read-more__button {
	display: flex;
	justify-content: center;

	font-size: 300%;
	line-height: 1;
}

@media (max-width: $screen_one) {
	.about section {
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		justify-items: center;
	}
	
	.about__picture {
		grid-column: 1;
		grid-row: 3;

		display: flex;
		justify-content: center;

		padding-right: 0;
	}

	.about__picture__overlay {
		width: 100%;
	}

	.about__picture > img {
		max-width: 700px;
	}

  .about .container__content {
		grid-column: 1;
		grid-row: 2;
		align-items: center;

		padding-left: 0;
	}

	.about .expander {
		max-width: 700px;
	}
}

@media (max-width: $screen_two) {
	.about__picture__overlay {
		display: none;
	}
}
