.audio-palyer {
	display: flex;
	flex-direction: column;

	position: relative;
  background-color: $gray;
  padding: 10px 0 40px 20px;
  font-size: 90%;
}

.audio-palyer__body {
	display: flex;

	margin-top: 10px;
}

.audio-palyer__media-links {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	
	width: 100%;
	padding-right: 20px;
	font-weight: 600;
}

.audio-palyer__media-links > a {
	color: $medium_gray;
	padding-left: 10px;
}

.audio-palyer__media-links > a:hover ,
.audio-palyer__media-links > a:active {
	color: $main_accent;
}

.audio-palyer__media-links > a {
	color: $medium_gray;
}

.audio-palyer__media-link {
	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: center;
}

.audio-palyer__media-link__icon {
	display: flex;
	align-items: center;
	align-content: center;

	margin-right: 2px;
}

.audio-palyer__media-link__text {
	font-size: 90%;
}

.audio-palyer__cover {
  margin-right: 30px;
}

.audio-palyer__cover__img {
  display: flex;
  align-items: center;
  justify-content: center;

  color: $main_accent;
  height: 170px;
  width: 170px;
  transition: 0.2s;
}

.audio-palyer__cover__img:hover,
.audio-palyer__cover__img:active {
  color: $medium_gray;
}

.audio-palyer.playing .audio-palyer__cover__img {
  transform: scale(1.04) translateY(5px);
}

.audio-palyer__cover__img > img {
  position: absolute;
  border-radius: 5px;
  object-fit: cover;
  height: inherit;
  width: inherit;
}

.audio-palyer__cover__img > .player-icon {
  position: absolute;
  font-size: 800%;
  z-index: 1;
  transition: opacity 0.2s;
}

.audio-palyer__cover__img > .player-icon--play {
  opacity: 1;
}

.audio-palyer.playing .audio-palyer__cover__img > .player-icon--play {
  opacity: 0;
}

.audio-palyer__cover__img > .player-icon--pause {
  opacity: 0;
}

.audio-palyer.playing .audio-palyer__cover__img > .player-icon--pause {
  opacity: 1;
}

.audio-palyer__cover__amount {
  color: $medium_gray;
  font-size: 90%;
  margin-top: 20px;
}

.audio-palyer .paypal-btns {
  position: relative;
  z-index: 0;
}

.audio-palyer__cover__paypal {
  margin-top: 10px;
}

.audio-palyer__content {
  width: 100%;
}

.audio-palyer__album-name {
  font-size: 140%;
  font-weight: 500;
  letter-spacing: -0.28px;
  line-height: 90%;
}

.audio-palyer__artist-name {
  color: $medium_gray;
  padding-top: 4px;
}

.audio-palyer__artist-name.audio-palyer__track-name {
  color: $main_accent;
}

/* Controls */
.audio-palyer__content__controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

	height: 0;
	opacity: 0;
	margin-right: 20px;
	transition: all 0.5s ease;
}

.audio-palyer.playing .audio-palyer__content__controls,
.audio-palyer.paused .audio-palyer__content__controls {
	height: auto;
	opacity: 1;
}

.audio-palyer__content__controls .progress-icons {
  display: flex;
	align-items: center;
	
	color: $medium_gray;
}

.audio-palyer__content__controls .progress-icons:hover,
.audio-palyer__content__controls .progress-icons:active {
	color: $main_accent;
}

.player-icon__small {
  font-size: 250%;
  transition: opacity 0.2s;
}

.audio-palyer__content__controls .progress-bar {
  display: flex;
  align-items: center;

	position: relative;
  width: 100%;
	margin-left: 10px;
}

.audio-palyer__content__controls .progress-bar__bg {
  -webkit-appearance: none;
  background: transparent;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.audio-palyer__content__controls .progress-bar__fg {
	position: absolute;
  height: 3px;
  background-color: $medium_gray;
  border-radius: 2.5px;
  pointer-events: none;
	z-index: 3;
}

.audio-palyer__content__controls .progress-bar__bg::-webkit-slider-runnable-track {
  cursor: pointer;
  height: 3px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 100%;
  background: white;
	border-radius: 3px;
	z-index: 2;
}

.audio-palyer__content__controls .progress-bar__bg::-moz-range-track {
  cursor: pointer;
  height: 3px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 100%;
	background: white;
	border-radius: 3px;
	z-index: 2;
}

.audio-palyer__content__controls .progress-bar__bg::-ms-track {
  cursor: pointer;
  height: 3px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 100%;
  background: transparent;
  border-color: transparent;
  border-width: 4px 0;
	color: transparent;
	z-index: 3;
}

.audio-palyer__content__controls .progress-bar__bg::-webkit-slider-thumb {
  background: $medium_gray;
  border-radius: 100%;
  cursor: pointer;
  height: 8px;
	width: 8px;
	margin-top: -2px;
  border: none;
  z-index: 3;
  -webkit-appearance: none;
}

.audio-palyer__content__controls .progress-bar__bg::-moz-range-thumb {
  background: $medium_gray;
  border-radius: 100%;
  cursor: pointer;
  height: 8px;
  width: 8px;
  border: none;
  z-index: 3;
}

.audio-palyer__content__controls .progress-bar__bg::-ms-fill-lower {
  background: #d3d3dc;
  border-radius: 6px;
}

.audio-palyer__content__controls .progress-bar__bg::-ms-fill-upper {
  background: #e1e1e7;
  border-radius: 6px;
}

.audio-palyer__content__controls .progress-bar__bg::-ms-thumb {
  background: $medium_gray;
  border-radius: 100%;
  cursor: pointer;
  height: 8px;
  width: 8px;
  border: none;
}

.audio-palyer__content__controls .progress-bar:hover .progress-bar__fg {
	-webkit-transition: background-color 0.2s ease;
	transition: background-color 0.2s ease;
	background-color: $main_accent;
}

.audio-palyer__content__controls .progress-bar:hover .progress-bar__bg::-webkit-slider-thumb {
  transition: background-color 0.2s ease, height 0.2s ease, width 0.2s ease, margin-top 0.2s ease;
  background-color: $main_accent;
  height: 20px;
	width: 20px;
	margin-top: -8px;
  border: 2px solid $gray;
}

.audio-palyer__content__controls .progress-bar:hover .progress-bar__bg::-moz-range-thumb {
  transition: background-color 0.2s ease, height 0.2s ease, width 0.2s ease, margin-top 0.2s ease;
  background-color: $main_accent;
  height: 20px;
  width: 20px;
  border: 2px solid $gray;
}

.audio-palyer__content__controls .progress-bar:hover .progress-bar__bg::-ms-thumb {
  transition: background-color 0.2s ease, height 0.2s ease, width 0.2s ease, margin-top 0.2s ease;
  background-color: $main_accent;
  height: 20px;
  width: 20px;
  margin-top: -10px;
  margin-bottom: -8px;
  border: 2px solid $gray;
}

// ======================================

.audio-palyer__content__controls .track-timer {
  min-width: 35px;
  color: $medium_gray;
  font-size: 90%;
  margin-left: 10px;
}

/* Tracklist */
.audio-palyer__tracklist {
  height: 200px;
  margin-top: 15px;
  margin-left: -20px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.audio-palyer__tracklist::before {
  content: "";
  display: block;
  opacity: 0;
  background-image: linear-gradient(180deg, rgb(27, 27, 27) 0%, rgba(27, 27, 27, 0) 100%);
  height: 10px;
  top: 0px;
  width: 100%;
  position: sticky;
  transition: opacity 0.1s;
}

.audio-palyer__tracklist.top-gradient-visible::before {
  opacity: 1;
}

.audio-palyer__tracklist .tracklist__tracks {
  border-top: 1px solid rgba(100, 100, 100, 0.5);
  border-bottom: 1px solid rgba(100, 100, 100, 0.5);
  margin: 0;
  margin-left: 20px;
  margin-right: 20px;
  padding: 0;
}

.audio-palyer__tracklist .tracklist__track {
  display: flex;
  align-items: center;

  cursor: pointer;
  transition: color 0.3s;
}

.audio-palyer__tracklist .tracklist__track:hover {
  color: $main_accent;
}

.audio-palyer__tracklist .tracklist__track__index {
  min-width: 30px;
  color: $medium_gray;
  padding-left: 7px;
}

.audio-palyer__tracklist .tracklist__track__name {
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid rgba(100, 100, 100, 0.5);
  padding: 10px 0;
  width: 100%;
}

.audio-palyer__tracklist .tracklist__track:last-child div {
  border-bottom: none;
}

.audio-palyer__tracklist .tracklist__track__links {
  border-bottom: 1px solid rgba(100, 100, 100, 0.5);
  padding: 10px 0;
}

.audio-palyer__tracklist .tracklist__track__links > a {
  border: 1px solid $medium_gray;
  padding: 3px 5px;
  border-radius: 4px;
  transition: all .2s ease-in-out;
}

.audio-palyer__tracklist .tracklist__track__links > a:hover,
.audio-palyer__tracklist .tracklist__track__links > a:active {
  border-color: $main_accent;
}

.audio-palyer__tracklist .track-playing .tracklist__track__name {
  color: $main_accent;
}

.audio-palyer .tracklist__footer {
  color: $medium_gray;
  font-size: 90%;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

/* Playing Animation */
.bars {
  display: flex;
  align-items: center;

  height: 20px;
  min-width: 30px;
  padding-left: 7px;
  transition: all 0.2s ease;
}

.bars .bar {
  height: 2px;
  min-width: 3px;
  margin-right: 1px;
  background-color: $main_accent;
  animation-duration: initial;
  animation-timing-function: initial;
  animation-delay: initial;
  animation-iteration-count: initial;
  animation-direction: initial;
  animation-fill-mode: none;
  animation-play-state: initial;
  animation-name: none;
}

.bars.wavy .bar {
  animation-name: wavy;
  animation-duration: 350ms;
  animation-play-state: running;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.bars .bar.one {
  animation-delay: 250ms;
}

.bars .bar.two {
  animation-delay: 715ms;
}

.bars .bar.three {
  animation-delay: 475ms;
}

.bars .bar.four {
  animation-delay: 25ms;
}

@keyframes wavy {
  from {
    height: 2px;
  }
  to {
    height: 14px;
  }
}

.audio-palyer--mobile .audio-palyer__cover__amount {
  display: none;
}

.audio-palyer--mobile .audio-palyer__cover__img {
  height: 120px;
  width: 120px;
}

.audio-palyer--mobile .audio-palyer__content__header__wrapper {
  display: flex;
  flex-direction: row;
}

.audio-palyer--mobile .audio-palyer__content__controls__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-top: 6px;
  margin-bottom: 6px;
}

.audio-palyer--mobile .audio-palyer__cover__paypal {
  display: none;
}

.audio-palyer--mobile .audio-palyer__cover__paypal--wide {
  margin-top: 10px;
  margin-right: 20px;
}
