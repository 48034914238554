.all-news section {
	grid-template-columns: 1fr;

	padding-top: 60px;
}

.all-news .container {
	align-items: start;
}

.all-news .container__title {
	grid-column: 1;
	grid-row: 1;
	justify-self: start;

	margin-left: 60px;
}

.all-news .container__content {
	grid-column: 1;
	grid-row: 2;
	justify-self: start;
}

.all-news .article-preview {
	display: flex;

	background-color: rgba($color: white, $alpha: 0.02);
	margin-bottom: 40px;

	transition: all 0.3s ease;
}

.all-news .article-preview:hover,
.all-news .article-preview:active {
	background-color: rgba($color: white, $alpha: 0.06);

	.article-preview__img img {
		transform: scale(1.05);
	}
}

.all-news .article-preview__img {
	overflow: hidden;
	width: 340px;
	min-width: 340px;
	height: 340px;
	border-right: 6px solid $main_accent;
}

.all-news .article-preview__img img {
	object-fit: cover;
	width: 100%;
	height: 100%;
	transition: all 0.3s ease;
}

.all-news .article-preview__body {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	
	width: 100%;
	margin: 2% 6%;
}

.article-preview__header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		width: 100%;
}

.all-news .article-preview__title {
	a, a:visited {
		color: $main_accent;
	}
	
	a:hover {
		color: white;
	}
}

.all-news .article-preview__date {
	color: $main_accent;
}

.all-news .article-preview__description {
	margin: 20px 0;
}

.all-news .article-preview__button {
	margin-top: auto;
}

@media (max-width: 900px) {
	.all-news .article-preview__img {
		width: 240px;
		min-width: 240px;
		height: 240px;
	}
}

@media (max-width: 750px) {
	.all-news .article-preview {
		flex-direction: column;

		background-color: rgba($color: white, $alpha: 0.06);
	}

	.all-news .article-preview__img {
		width: 100%;
		height: 340px;
		border-right: none;
		border-bottom: 6px solid $main_accent;
	}

	.all-news .article-preview__body {
		align-items: center;
		
		width: auto;
		margin: 3% 4%;
	}
}

@media (max-width: $screen_two) {
	.all-news .container {
		padding-bottom: 20px;
	}

	.all-news .container__title {
    margin-left: 0.8rem;
	}

	.article-preview__header {
		flex-direction: column;
	}

	.all-news .article-preview__date {
		margin-top: 15px;
	}

	a.button, a.button:visited {
    padding: 15px 0;
    width: 100%;
    text-align: center;
	}
}
