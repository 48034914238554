// colors
$brown_light: #D9946C;
// $brown: #A56A49;
$main_accent: #c41717;
$brown_dark: #592828;

$bright_gray: #E1E1E7;
$medium_gray: #A4AAB3;
$gray: #222222;
$gray_dark: #1A1A1A;

// Screen size breakpoints
$screen_zero: 1200px;
$screen_one: 1040px;
$screen_two: 580px;
