.news section {
	grid-template-columns: 4fr auto;
}

.news .container {
	align-items: start;
	
	margin-top: 40px;
}

.news .container__title {
	grid-column: 2;
	grid-row: 1;

	margin-left: 40px;
	margin-right: 60px;
}

.news .container__content {
	grid-column: 1;
	grid-row: 1;
	align-items: center;
	justify-content: center;

	margin: 0;
	margin-right: 20px;
}

.news__article {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.news__article__block {
	display: inline-block;
	width: 350px;
	height: calc(350px * 0.56);
	box-shadow: 0 0 10px black;
	margin: 10px;
	transition: all .2s ease-in-out;
}

.news__article__block:hover {
	box-shadow: none;
	transform: scale(1.05);

	.news__article__img {
		filter: blur(1.5px);
		opacity: 0.5;
	}

	.news__article__brief {
		opacity: 1;
	}
}

.news__article__img {
	position: absolute;
	
	height: inherit;
	width: inherit;
	object-fit: cover;
	opacity: 1;

	transition: opacity 0.2s;
}

.news__article__brief {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	position: relative;

	opacity: 0;
	text-align: center;
	color: white;
	height: inherit;
	width: inherit;
	padding-bottom: 10px;
	transition: opacity 0.2s;
	z-index: 1;
}

.news__article__title {
	color: white;
	text-align: center;
	transition: all .2s ease-in-out;
}

.news__article__title:hover,
.news__article__title:active {
	color: $main_accent;
}

.news__article__description {
	padding: 10px;
}

.news__controls {
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	border-top: 1px solid $main_accent;
	margin-top: 20px;
}

.news__controls__arrows {
	display: flex;

	font-size: 400%;
}

.news__controls__arrows > a {
	display: flex;
	align-items: center;
}

.news .news__controls__all {
	margin-right: 14px;
}

.BrainhubCarousel {
	display: flex;
	align-items: center;

	position: relative;
	width: 100%;
	
  .BrainhubCarousel__trackContainer {
		position: relative;
		overflow: hidden;
		width: 100%;
		
    .BrainhubCarousel__track {
			display: flex;
			flex-direction: row;

			position: absolute;
			overflow: hidden;
			z-index: 1;

      &.BrainhubCarousel__track--transition {
				transition: transform;
      }
    }
  }
}

.BrainhubSliderItem {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media (max-width: $screen_one) {
	.news section {
		grid-template-columns: 1fr;
	}

	.news .container {
		margin-top: 0;
	}

  .news .container__title {
		grid-column: 1;
		grid-row: 1;
	}

	.news .container__content {
		grid-column: 1;
		grid-row: 2;
	}
}

@media (max-width: $screen_two) {
	// .news__article__block {
	// 	width: 88vw;
	// 	height: calc(88vw * 0.56);
	// }

	// .news__article__brief {
	// 	opacity: 1;
	// }

	// .news__article__description {
	// 	background-color: rgba($color: black, $alpha: 0.4);
	// 	box-shadow: 
  //       inset 0px 10px 10px -10px rgba($color: black, $alpha: 0.4),
	// 			inset 0px -10px 10px -10px rgba($color: black, $alpha: 0.4);
	// 	padding: 10px 5px;
	// }
}
