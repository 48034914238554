.albums {
	width: 100%;
}

.albums__album {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.albums__album__block {
	display: inline-block;
	width: 220px;
	height: 220px;
	box-shadow: 0 0 10px black;
	margin: 10px;
	transition: all .2s ease-in-out;
}

.albums__album__block:hover {
	box-shadow: none;
	transform: scale(1.05);

	.albums__album__img {
		filter: blur(1.5px);
		opacity: 0.5;
	}

	.albums__album__brief {
		opacity: 1;
	}
}

.albums__album__img {
	position: absolute;
	
	height: inherit;
	width: inherit;
	object-fit: cover;
	opacity: 1;

	transition: opacity 0.2s;
}

.albums__album__brief {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	position: relative;

	opacity: 0;
	text-align: center;
	color: white;
	height: inherit;
	width: inherit;
	padding-bottom: 10px;
	transition: opacity 0.2s;
	z-index: 1;
}

.albums__album__description {
	padding: 10px;
}

.albums__controls {
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 100%;
	border-top: 1px solid $main_accent;
	margin-top: 20px;
}

.albums__controls__arrows {
	display: flex;

	font-size: 400%;
}

.albums__controls__arrows > a {
	display: flex;
	align-items: center;
}

@media (max-width: $screen_two) {
	.albums__album__block {
		width: 88vw;
		height: 88vw;
	}

	.albums__album__brief {
		opacity: 1;
	}

	.albums__album__description {
		background-color: rgba($color: black, $alpha: 0.4);
		box-shadow: 
        inset 0px 10px 10px -10px rgba($color: black, $alpha: 0.4),
				inset 0px -10px 10px -10px rgba($color: black, $alpha: 0.4);
		padding: 10px 5px;
	}
}
