.video-player {
	position: relative;
  padding-top: 56.25%; // player aspect ratio
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
