.main-menu {
	position: fixed;
	left: 0;
	right: 0;
	width: 100%;
	transition: background-color 0.3s;
	z-index: 100;
}

.main-menu__bg {
	position: fixed;

	background-color: $gray;
	width: 100%;
	height: 0;
	transition: height 0.3s;
	z-index: -1;
}

.main-menu__bg--sticky {
	height: 60px;
}

.main-menu__links--top {
	padding-top: 30px;
}

.main-menu__links {
	display: flex;
	justify-content: space-between;
	align-items: center;

	font-size: 120%;
	height: 60px;
	padding-left: 3%;
	padding-right: 3%;
	transition: padding-top 0.3s;
}

.main-menu__links__logo {
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 700;
	letter-spacing: 2px;
}

.main-menu__links__logo > a,
.main-menu__links__logo > a:link,
.main-menu__links__logo > a:visited {
	color: $main_accent;
}

.main-menu__links__logo > a:hover,
.main-menu__links__logo > a:active {
	color: white;
}

.main-menu__links__menu {
	font-family: 'Oswald', sans-serif;
	letter-spacing: 3px;
}

#main-menu__toggle__input {
	display: none;
}

.main-menu__toggle {
	z-index: 100;
}

.main-menu__nav--bg {
	display: flex;

	position: fixed;
	background-color: $gray;
	top: 0;
	bottom: 0;
	right: 0;
	width: 315px;
	transition: 0.3s ease-in-out right;
	z-index: -1;
}

.main-menu__nav {
	display: flex;
	flex-direction: column;

	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	width: 315px;
	margin-top: 70px;
	overflow: auto;
	transition: 0.4s ease-in-out right;
}

#main-menu__toggle__input:not(:checked) {
	~ .main-menu__nav {
		right: -315px;
		transition: 0.3s ease-in-out right;
	}

	~ .main-menu__nav--bg {
		right: -315px;
	}
}

.main-menu__nav__links {
	display: block;
	list-style: none;
	text-transform: uppercase;
	border-left: 1px solid $main_accent;
	padding-left: 20px;
	margin-left: 60px;
}

.main-menu__nav__links li {
	display: block;
	padding: 15px;
	padding-left: 0;
	position: relative;
}

.main-menu__nav__links--pages {
	a, a:visited {
		color: $main_accent;
	}

	a:hover, a:active {
		color: white;
	}
}

.main-menu__nav__links--admin {
	border: 1px solid white;
	border-right: none;
}

.main-menu__nav__links--tags {
	text-transform: initial;
}

@media (max-width: $screen_two) {
	.main-menu__nav--bg {
		width: 100vw;
	}

	.main-menu__nav {
		width: 100vw;
	}
	
	#main-menu__toggle__input:not(:checked) {
		~ .main-menu__nav--bg {
			right: -100vw;
		}

		~ .main-menu__nav {
			right: -100vw;
		}
	}
}
