html {
  font-family: "SF Pro Text", "San Francisco", -apple-system, system-ui, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
}

body {
  display: flex;
  justify-content: center;

  width: 100vw;
  max-width: 100%;
  background-color: $gray_dark;
  color: white;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100vw;
}

* {
  box-sizing: border-box;
}

input[type="range"]::-moz-focus-outer {
    border: 0;
    outline : 0;
}

h1 {
  font-weight: 400;
  font-size: 380%;
  margin: 0;
}

h2 {
  display: flex;
  flex-direction: column;
  justify-self: flex-start;

  position: relative;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400;
  color: white;
  text-shadow: 0 0 6px black;
  font-size: 240%;
  margin: 0;
}

h2::before {
  content: "";
  position: absolute;
  height: 0.8rem;
  width: 100%;
  background-color: $main_accent;
  padding: 0 0.8rem;
  margin-left: -0.8rem;
  margin-right: 0.8rem;
  bottom: 0.6rem;
  z-index: -1;
}

h3 {
  font-weight: 400;
  color: white;
  font-size: 150%;
  margin: 0;
}

h4 {
  font-weight: 600;
  color: $main_accent;
  font-size: 100%;
  margin: 0;
}

a, a:visited {
  color: white;
  text-decoration: none;
  transition: color 0.2s;
}

a:hover {
  color: $main_accent;
  cursor: pointer;
}

a.button,
a.button:visited {
  color: white;
  background-color: transparent;
  border: 1px solid $main_accent;
  padding: 15px 34px;
  letter-spacing: 3px;
  font-size: 80%;
  text-transform: uppercase;
  transition: color 0.2s;
}

a.button:hover,
a.button:active {
  color: $main_accent;
  cursor: pointer;
}

.block-holder{
  display: flex;
  justify-content: center;
  position: relative;

  width: 100%;
}

section {
	display: grid;
  grid-auto-rows: min-content;
  grid-gap: 20px;
  
  width: 100%;
  max-width: 1140px;
  padding: 100px 0;
  margin: 0 40px;
}

.container__title {
  margin-bottom: 30px;
}

.container__content {
	display: flex;
  flex-direction: column;

  width: 100%;
	height: 100%;
}

.page {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
}

.page .connection-line--last {
  margin-top: auto;
}

@media (max-width: $screen_zero) {
  section {
    padding: 80px 0;
  }
}

@media (max-width: $screen_one) {
  h1 {
    font-size: 280%;
  }

  h2 {
    font-size: 200%;
  }

  h3 {
    font-size: 120%;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 8px;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border-radius: 8px;
  background: rgba(180, 180, 180, 0.8); 
  box-shadow: inset 0 0 6px rgba(0,0,0,0); 
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(180, 180, 180, 0.4); 
}

@media (max-width: $screen_two) {
  html {
    font-size: 12px;
  }

  section {
    grid-gap: 0;
    margin: 0 20px;
  }
}
