.contact_us section {
	grid-template-columns: 1fr;

	padding-bottom: 40px;
}

.container__title__wrapper{
	display: flex;
	justify-content: center;
}

.contact_us .container__title {
	align-self: flex-start;

	margin-bottom: 20px;
}

.contact_us .container__content__body {
	display: inline-block;

	margin-left: 60px;
}

.contact_us .container__content__body > * {
	display: inline-block;
	vertical-align: middle;
	margin-right: 8px;
}

.contact_us .container__content__icons {
	display: flex;
	align-items: center;
}

.contact_us .container__content__icons .icon {
	display: flex;
	align-items: center;

	margin-right: 8px;
}

.contact_us .container__content__icons .icon:last-child {
	margin-right: 0;
}

.contact_us .container__content__copyright {
	font-weight: 200;
	margin-left: 60px;
}

.contact_us .container__content__copyright > *{
	display: inline-block;
}

.contact_us__waves {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	position: absolute;
	overflow: hidden;
	height: 100%;
	width: 50%;
	bottom: 0;
	right: 0;
	z-index: -1;
}

.contact_us__waves > img {
	position: relative;
	opacity: 0.2;
	height: 80%;
	right: -10%;
	bottom: -10%;
}

@media (max-width: $screen_two) {
	.contact_us .container__content__body {
		margin-left: 0;
	}
	
	.contact_us .container__content__copyright {
		margin-left: 0;
	}

	.contact_us__waves {
		justify-content: center;
		width: 100%;
	}

	.contact_us__waves > img {
		opacity: 0.1;
	}
}
